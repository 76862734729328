<template>
  <div>
    <modal-password
      v-if="passwordModalIsOpen"
      :callback="deleteUser"
      @cancel="passwordModalIsOpen = false"
    />
    <modal-change-default
      v-if="changeDefaultIsOpen"
      :isOpen="changeDefaultIsOpen"
      :merchant="merchantSelected"
      @closeModal="hideChangeDefault"
    />
    <div class="eu-pt_3">
      <div class="el-chunk">
        <router-link
          :to="{ name: originRouteName, query: userQuery }"
          class="emd-btn _type_link"
          tag="button"
        >
          <i class="uil uil-arrow-left"/>
          {{ $t('back') }}
        </router-link>
      </div>
      <template v-if="selectedUser">
        <emd-alert
          v-if="showAlertUserDeleted"
          variant="success"
          @cancel="showAlertUserDeleted = false"
        >
          <template slot="content">
            <p>{{ $t('userDeleted') }}</p>
          </template>
        </emd-alert>

        <div class="eu-pb_1">
          <p
            class="emd-text _size_subpage"
            href="javascript:void(0)"
          >
            {{ selectedUser.first_name }} {{ selectedUser.last_name }}
          </p>
        </div>
        <div class="emd-text _size_small">
          <div
            v-if="selectedUser.email"
            class="emd-copy"
          >
            <emd-copy :item="selectedUser.email"/>
          </div>
          <div class="emd-copy">
            <emd-copy :item="selectedUser.id"/>
          </div>
          <div
            v-if="selectedUser.document_number"
            class="emd-copy"
          >
            <emd-copy :item="selectedUser.document_number"/>
          </div>
        </div>
      </template>
      <button
        v-if="showBtnRemove"
        class="emd-btn _color_danger eu-mt_3"
        @click="passwordModalIsOpen = true"
      >
        <i class="uil uil-trash"/>
        {{ $t('remove') }}
      </button>
    </div>

    <tfa-section/>

    <div
      class="el-section">
      <emd-tabs
        :tabs="tabs"
        @changed="changed"
      >
        <template v-slot:permissions_mundi>
          <permissions-wrapper
            :loading="loadingList"
            :permissions="permissions"
            @openModalChangeDefault="openModalChangeDefault"
          />
        </template>

        <template #permissions_pagarme>
          <permissions-wrapper
            :loading="loadingList"
            :permissions="permissions_pagarme"
            @openModalChangeDefault="openModalChangeDefault"
          />
        </template>
      </emd-tabs>
    </div>
  </div>
</template>

<i18n>
{
  "pt-BR": {
    "back": "Voltar",
    "remove": "Remover",
    "defaultAccount": "Conta padrão",
    "permission": "Permissão",
    "permissionMundi": "Permissão 2.0",
    "permissionPagarme": "Permissão 1.0",
    "configCompany": "Configurar no Company",
    "primaryAccess": "Acesso principal",
    "setAsPrimary": "Definir como principal",
    "userDeleted": "Usuário removido com sucesso."
  },
  "en-US": {
    "back": "Back",
    "remove": "Remove",
    "defaultAccount": "Default account",
    "searchUser": "Search User",
    "permission": "Permission",
    "permissionMundi": "Permission 2.0",
    "permissionPagarme": "Permission 1.0",
    "configCompany": "Configure on Company",
    "primaryAccess": "Primary access",
    "setAsPrimary": "Set as primary",
    "userDeleted": "User removed successfully."
  }
}
</i18n>

<script>
import EmdCopy from '@/components/emerald/molecules/EmdCopy.vue'
import ModalPassword from '@/components/project/globals/ModalPassword.vue'
import ModalChangeDefault from '@/components/project/globals/ModalChangeDefault.vue'
import TfaSection from '@/components/project/tfa/TfaSection.vue'
import { EmdAlert, EmdTabs } from 'emerald-vue/src/components'
import { mapActions, mapGetters } from 'vuex'
import PermissionsWrapper from '@/components/project/ui/PermissionsWrapper.vue'

const TABS_TYPES = {
  'version_2': 'permissions_mundi', // v2
  'version_1': 'permissions_pagarme' // v1
}

export default {
  name: 'UsersDetail',
  components: {
    EmdTabs,
    EmdCopy,
    EmdAlert,
    ModalPassword,
    ModalChangeDefault,
    TfaSection,
    PermissionsWrapper
  },
  data () {
    return {
      loadingUser: true,
      loadingList: true,
      permissions: [],
      permissions_pagarme: [],
      showAlertUserDeleted: false,
      merchants: null,
      passwordModalIsOpen: false,
      changeDefaultIsOpen: false,
      merchantSelected: null,
      tabs: [
        {
          slotName: TABS_TYPES.version_2,
          enabled: true,
          label: this.$t('permissionMundi'),
        },
        {
          slotName: TABS_TYPES.version_1,
          enabled: true,
          label: this.$t('permissionPagarme'),
        },
      ]
    }
  },
  computed: {
    ...mapGetters(['selectedUser', 'userBackQuery', 'user']),
    userQuery () {
      return this.userBackQuery.query
    },
    originRouteName () {
      return this.userBackQuery.name
    },
    userId () {
      return this.$route.params.user_id
    },
    showBtnRemove () {
      return (
        this.$route.name === 'employeeDetail' &&
        !this.loadingUser &&
        this.permissionIsEnabled('employee:remove') &&
        this.user.email !== this.selectedUser.email
      )
    }
  },
  methods: {
    ...mapActions(['GET_USER_PERMISSIONS', 'GET_PAGARME_USER_PERMISSIONS', 'GET_USER_BY_ID', 'DELETE_EMPLOYEE']),
    getUser () {
      this.$store.commit('setTfaState', false)
      this.GET_USER_BY_ID(this.$route.params.user_id)
        .then(res => {
          this.$store.dispatch('SET_SELECTED_USER', res)
          if (res.user_two_factor_authentication?.enabled) {
            this.$store.commit('setTfaState', true)
          }
          this.loadingUser = false
        })
    },
    changed ({ slotName }) {
      const actions = {
        permissions_mundi: () => this.fetchPermissionsMundi(),
        permissions_pagarme: () => this.fetchPermissionsPagarme()
      }

      const actionFunction = actions[slotName]
      if (actionFunction) {
        actionFunction()
      }
    },

    fetchPermissionsPagarme () {
      this.loadingList = true
      this.permissions_pagarme = []

      this.GET_PAGARME_USER_PERMISSIONS(this.userId)
        .then(res => {
          res.forEach(permission => this.permissions_pagarme.push(permission))
        })
        .finally(() => {
          this.loadingList = false
        })
    },

    fetchPermissionsMundi () {
      this.loadingList = true
      this.permissions = []

      this.GET_USER_PERMISSIONS(this.userId)
        .then(res => {
          res.forEach(permission => this.permissions.push(permission))
        })
        .finally(() => {
          this.loadingList = false
        })
    },

    urlCompany (merchId) {
      return `${process.env.VUE_APP_COMPANY_URL}/${merchId}`
    },
    openPasswordModal () {
      this.passwordModalIsOpen = true
    },
    closePasswordModal () {
      this.passwordModalIsOpen = false
    },
    deleteUser (password) {
      const body = {
        user_id: this.selectedUser.id,
        body: { password: password }
      }
      return this.DELETE_EMPLOYEE(body).then(() => {
        this.passwordModalIsOpen = false
        this.showAlertUserDeleted = true
        setTimeout(() => {
          this.$router.push({
            name: this.originRouteName,
            query: this.userQuery
          })
        }, 7000)
      })
    },
    openModalChangeDefault (merchant) {
      if (merchant.is_default) return
      this.merchantSelected = merchant
      this.changeDefaultIsOpen = true
    },
    hideChangeDefault (event) {
      if (this.changeDefaultIsOpen) {
        this.changeDefaultIsOpen = false
        if (event.changed) {
          this.permissions = []
          this.fetchPermissionsMundi()
        }
      }
    },
    permissionIsEnabled (action) {
      return this.$store.getters.permissionIsEnabled(action)
    }
  },
  beforeMount () {
    this.getUser()
    this.fetchPermissionsMundi()
  }
}
</script>
