<template>
  <div
    class="eu-text-overflow emd-copy"
    :class="{ _copied: copied }"
    @click="copy(item)"
  >
    <span
      class="emd-copy__icon"
      :title="copied ? $t('copied') : $t('copy')"
    >
      <i
        class="uil uil-check-circle"
        v-if="copied"
      />
      <i
        class="uil uil-copy"
        v-else
      />
    </span>
    <p class="er-copy__text emd-copy__text">
      <span :ref="item">{{ item }}</span>
    </p>
  </div>
</template>

<i18n>
{
  "pt-BR": {
    "copy": "copiar",
    "copied": "copiado"
  },
  "en-US": {
    "copy": "copy",
    "copied": "copied"
  }
}
</i18n>

<script>
export default {
  name: 'EmdCopy',
  props: {
    item: {
      type: String,
      default: null,
      required: true
    }
  },
  data () {
    return {
      copied: false
    }
  },
  methods: {
    copy (item) {
      var element
      if (Array.isArray(this.$refs[item])) {
        element = this.$refs[item][0]
      } else {
        element = this.$refs[item]
      }
      var range = document.createRange()
      range.selectNode(element)
      window.getSelection().removeAllRanges()
      window.getSelection().addRange(range)
      document.execCommand('copy')
      window.getSelection().removeAllRanges()

      this.copied = true
      setTimeout(() => {
        this.copied = false
      }, 1000)
    }
  }
}
</script>
