<template>
  <div>
    <div
      v-if="tfaIsEnabled"
      class="el-section eu-pb_5"
    >
      <p class="emd-text _size_subpage eu-mt_5">
        {{ $t('title') }}
      </p>

      <button
        @click="activeDeleteModal"
        class="emd-btn _color_info _type_outline _block_mobile eu-basis_4 eu-basis_4_lg eu-basis_4_xl eu-basis_6_md eu-basis_6_sm eu-basis_12_mobile eu-mb_3 eu-mt_4 position-relative"
      >
        <span>{{ $t('reset-button') }}</span>
        <i class="uil uil-padlock eu-ml_2 padlock-button"></i>
      </button>

      <p class="emd-text _color_subtle eu-mt_3 hide-element">
        {{ $t('description') }}
      </p>
    </div>
    <div
      v-if="!tfaIsEnabled && notActiveAlert"
      class="el-section eu-pb_5 max-width"
    >
      <p class="emd-text _size_subpage eu-mt_5">
        {{ $t('title') }}
      </p>
      <emd-alert
        v-if="notActiveAlert"
        class="eu-mt_5"
        variant="info"
        :title="$t('alert-title')"
        @cancel="notActiveAlert = false"
        :autoclose="false"
      >
        <template slot="content">
          {{ $t('alert-description') }}
        </template>
      </emd-alert>
    </div>
    <tfa-modal
      :modalState="tfaModalState"
      @update:modalState="tfaModalState = $event"
    />
  </div>
</template>

<i18n>
{
  "pt-BR": {
    "title": "Segurança",
    "reset-button": "Redefinir autenticação em dois fatores",
    "description": "Retorna a autenticação em dois fatores deste usuário ao estado de nunca configurada. Da próxima vez que o usuário fizer login, será obrigatório que ele configure novamente a 2FA.",
    "alert-title": "A autenticação em dois fatores está desativada",
    "alert-description": "Esta conta ainda não fez a configuração da autenticação em dois fatores."
  },
  "en-US": {
    "title": "Security",
    "reset-button": "Reset your two-factor authentication",
    "description": "Returns the that user two-factor authentication to the never configured state. In the next time that the user does the login, it will be required to configure the 2FA again",
    "alert-title": "The two-factor authentication is disabled",
    "alert-description": "This account not yet configured the two-factor authentication"
  }
}
</i18n>

<script>
import TfaModal from './TfaModal.vue'
import { EmdAlert } from 'emerald-vue/src/components'

export default {
  name: 'TfaSection',
  components: {
    TfaModal,
    EmdAlert
  },
  computed: {
    tfaIsEnabled () {
      return this.$store.state.user.tfaEnabled
    }
  },
  watch: {
    tfaModalState () {
      this.getTfaState()
    }
  },
  data () {
    return {
      tfaModalState: false,
      error: false,
      notActiveAlert: true
    }
  },
  methods: {
    activeDeleteModal () {
      this.tfaModalState = true
    },
    getTfaState () {
      this.$store
        .dispatch('GET_USER_BY_ID', this.$route.params.user_id)
        .then(res => {
          if (res.user_two_factor_authentication) {
            this.notActiveAlert = false
            this.$store.commit('setTfaState', true)
          } else {
            this.notActiveAlert = true
            this.$store.commit('setTfaState', false)
          }
        })
    }
  }
}
</script>

<style scoped>
.padlock-button::before {
  position: absolute;
  right: 18px;
  font-size: 16px;
}

.position-relative {
  position: relative;
}

.max-width {
  max-width: 587px;
}
</style>
